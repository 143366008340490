import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import dreamApi from '../../../adapters/dreamApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      position: 'absolute',
    },
    dialogContent: {
      position: 'relative',
    },
    error: {
      marginTop: theme.spacing(4),
      color: '#FF0000',
    },
  })
);

type Inputs = {
  name: string;
  email: string;
  discordId: string;
  discordName: string;
  patreonId: string;
  patreonName: string;
  notes: string;
};

function AddPlayerForm({ open, submit, close, refresh, playerData }: any) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const error = useRef<any>(undefined);

  const onSubmit = () => {
    error.current = undefined;
    setLoading(true);
    dreamApi
      .delete(`players/${playerData.id}`)
      .then(({ data }) => {
        console.log(data);
        refresh();
        close();
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        error.current = err;
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">Delete Player</DialogTitle>
      <DialogContent dividers={true} className={classes.dialogContent}>
        <DialogContentText>
          Are you sure you want to delete player: {playerData.name}?
        </DialogContentText>
        {loading && (
          <Backdrop
            className={classes.backdrop}
            open={loading}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {error.current && (
          <DialogContentText className={classes.error}>
            {JSON.stringify(error.current)}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddPlayerForm;
