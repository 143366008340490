import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Container } from '@material-ui/core';

import dreamApi, { useDreamApi } from '../../adapters/dreamApi';
import LicencesTable from './components/LicencesTable';
import AddLicenceForm from './components/AddLicenceForm';
import EditLicenceForm from './components/EditLicenceForm';
import DeleteLicenceForm from './components/DeleteLicenceForm';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    minWidth: 275,
  },
  container: {
    paddingTop: 96,
  },
});

function LicencesPage() {
  const classes = useStyles();

  const [openAddForm, setOpenAddForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const selectedLicence = useRef<any>(undefined);

  const onAddButtonClick = () => {
    setOpenAddForm(true);
  };

  const [licences, loadingLicences, errorLicences, fetchLicences] = useDreamApi(
    {
      config: {
        url: 'licences',
        method: 'GET',
      },
    }
  );

  const [players, loadingPlayers, errorPlayers, fetchPlayers] = useDreamApi({
    config: {
      url: 'players',
      method: 'GET',
    },
  });

  useEffect(() => {
    fetchLicences();
    fetchPlayers();
  }, []);

  const onEditButtonClick = (player: any) => {
    selectedLicence.current = player;
    setOpenEditForm(true);
  };

  const onDeleteButtonClick = (player: any) => {
    selectedLicence.current = player;
    setOpenDeleteForm(true);
  };

  const onCloseAddForm = () => {
    setOpenAddForm(false);
  };

  const onCloseEditForm = () => {
    selectedLicence.current = undefined;
    setOpenEditForm(false);
  };

  const onCloseDeleteForm = () => {
    selectedLicence.current = undefined;
    setOpenDeleteForm(false);
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <LicencesTable
            licences={licences || []}
            players={players || []}
            onAddButtonClick={onAddButtonClick}
            onEditButtonClick={onEditButtonClick}
            onDeleteButtonClick={onDeleteButtonClick}
            loading={loadingLicences || loadingPlayers}
          />
        </CardContent>
      </Card>
      {openAddForm && (
        <AddLicenceForm
          open={openAddForm}
          close={onCloseAddForm}
          refresh={fetchLicences}
          players={players || []}
        />
      )}
      {openEditForm && (
        <EditLicenceForm
          open={openEditForm}
          close={onCloseEditForm}
          refresh={fetchLicences}
          licenceData={selectedLicence.current}
          players={players || []}
        />
      )}
      {openDeleteForm && (
        <DeleteLicenceForm
          open={openDeleteForm}
          close={onCloseDeleteForm}
          refresh={fetchLicences}
          licenceData={selectedLicence.current}
        />
      )}
    </>
  );
}

export default LicencesPage;
