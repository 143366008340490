import DateFnsUtils from '@date-io/date-fns';
import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dreamApi, { useDreamApi } from '../../../adapters/dreamApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      position: 'absolute',
    },
    dialogContent: {
      position: 'relative',
    },
    error: {
      marginTop: theme.spacing(4),
      color: '#FF0000',
    },
    formControl: {
      minWidth: 120,
    },
    controlsMiddle: {
      textAlign: 'center',
      margin: 'auto',
    },
    plusMonthBtn: {
      marginTop: 24,
      marginBottom: 8,
    },
  })
);

type Inputs = {
  expirationDate: Date;
  playerId: number;
  status: number;
  name: string;
  email: string;
  discordId: string;
  discordName: string;
  patreonId: string;
  patreonName: string;
  notes: string;
};

function removeUnchangedFields(data: any, original: any) {
  Object.keys(data).forEach((key) => {
    if (data[key] === original[key]) {
      delete data[key];
    }
  });
}

function EditPlayerForm({
  open,
  submit,
  close,
  refresh,
  licenceData,
  players,
}: any) {
  const classes = useStyles();
  // const [loading, setLoading] = useState(false);
  // const error = useRef<any>(undefined);

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    moment(licenceData.expirationDate).toDate()
  );

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<Inputs>();

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const addMonthToDate = () => {
    setValue(
      'expirationDate',
      moment(getValues().expirationDate)
        .add(1, 'months')
        .set('hours', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .toDate(),
      {
        shouldValidate: true,
        shouldDirty: true,
      }
    );
  };

  const [, loading, error, updateLicence] = useDreamApi({
    config: {
      url: `licences/${licenceData.id}`,
      method: 'PATCH',
    },
    onSuccess: (data) => {
      console.log(data);
      refresh();
      close();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const onSubmit = (data: any) => {
    removeUnchangedFields(data, licenceData);
    console.log(data);
    console.log(licenceData);
    updateLicence(data);
    // error.current = undefined;
    // setLoading(true);
    // console.log(data);
    // dreamApi
    //   .patch(`players/${licenceData.id}`, data)
    //   .then(({ data }) => {
    //     console.log(data);
    //     refresh();
    //     close();
    //     setLoading(false);
    //   })
    //   .catch((err: any) => {
    //     console.log(err);
    //     error.current = err;
    //     setLoading(false);
    //   });
  };

  const player = players.find((elem: any) => {
    return elem.id === licenceData.playerId;
  });

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">Modify Licence</DialogTitle>
      <DialogContent dividers={true} className={classes.dialogContent}>
        <form>
          <TextField
            autoFocus
            margin="dense"
            id="licenceKey"
            label="Licence Key"
            type="text"
            defaultValue={licenceData.licenceKey}
            fullWidth
            disabled
          />
          <div className={classes.controlsMiddle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="expirationDate"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="expirationDate"
                    label="Expiration Date"
                    value={value}
                    onChange={onChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                )}
                control={control}
                defaultValue={selectedDate}
              />
            </MuiPickersUtilsProvider>
            <Button
              onClick={addMonthToDate}
              color="primary"
              variant="contained"
              className={classes.plusMonthBtn}
            >
              + 1 month
            </Button>
          </div>

          <Controller
            name="playerId"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <Autocomplete
                id="playerId"
                options={players}
                getOptionLabel={(option: any) =>
                  `${option.name} | ${option.email ?? ''}`
                }
                style={{ width: 300 }}
                onChange={(_, data) => onChange((data && data.id) || null)}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Player"
                    // variant="outlined"
                    value={params}
                  />
                )}
                defaultValue={player}
              />
            )}
            control={control}
            defaultValue={player.id}
          />
          <Controller
            name="status"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="status"
                    onChange={onChange}
                    inputRef={ref}
                    value={value}
                    defaultValue={licenceData.status}
                  >
                    <MenuItem value={1000}>Disabled</MenuItem>
                    <MenuItem value={2000}>New</MenuItem>
                    <MenuItem value={3000}>Active</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
            control={control}
            defaultValue={licenceData.status}
          />
          {loading && (
            <Backdrop
              className={classes.backdrop}
              open={loading}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {error.current && (
            <DialogContentText className={classes.error}>
              {JSON.stringify(error.current)}
            </DialogContentText>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditPlayerForm;
