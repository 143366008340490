import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Container } from '@material-ui/core';

import dreamApi, { useDreamApi } from '../../adapters/dreamApi';
import ClientsTable from './components/ClientsTable';
import AddClientForm from './components/AddClientForm';
import EditLicenceForm from './components/EditLicenceForm';
import DeleteClientForm from './components/DeleteClientForm';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    minWidth: 275,
  },
  container: {
    paddingTop: 96,
  },
});

function ClientsPage() {
  const classes = useStyles();

  const [openAddForm, setOpenAddForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const selectedClient = useRef<any>(undefined);

  const onAddButtonClick = () => {
    setOpenAddForm(true);
  };

  const [clients, loadingClients, errorClients, fetchClients] = useDreamApi({
    config: {
      url: 'clients',
      method: 'GET',
    },
  });

  useEffect(() => {
    fetchClients();
  }, []);

  const onEditButtonClick = (player: any) => {
    selectedClient.current = player;
    setOpenEditForm(true);
  };

  const onDeleteButtonClick = (player: any) => {
    selectedClient.current = player;
    setOpenDeleteForm(true);
  };

  const onCloseAddForm = () => {
    setOpenAddForm(false);
  };

  const onCloseEditForm = () => {
    selectedClient.current = undefined;
    setOpenEditForm(false);
  };

  const onCloseDeleteForm = () => {
    selectedClient.current = undefined;
    setOpenDeleteForm(false);
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <ClientsTable
            clients={clients || []}
            onAddButtonClick={onAddButtonClick}
            onEditButtonClick={onEditButtonClick}
            onDeleteButtonClick={onDeleteButtonClick}
            loading={loadingClients}
          />
        </CardContent>
      </Card>
      {openAddForm && (
        <AddClientForm
          open={openAddForm}
          close={onCloseAddForm}
          refresh={fetchClients}
        />
      )}
      {openEditForm && (
        <EditLicenceForm
          open={openEditForm}
          close={onCloseEditForm}
          refresh={fetchClients}
          licenceData={selectedClient.current}
        />
      )}
      {openDeleteForm && (
        <DeleteClientForm
          open={openDeleteForm}
          close={onCloseDeleteForm}
          refresh={fetchClients}
          clientData={selectedClient.current}
        />
      )}
    </>
  );
}

export default ClientsPage;
