import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { LicenceDto } from '../../../typings';
import { STATUSES } from '../../../consts/constants';
import moment from 'moment';
import { Typography } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bolder',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: '#FF0000',
    },
  },
}))(TableRow);

type Props = {
  licenceData: LicenceDto;
  players: [];
  onEdit: any;
  onDelete: any;
};

function PlayersTableRow({ licenceData, players, onEdit, onDelete }: Props) {
  const onEditButtonClick = () => {
    onEdit(licenceData);
  };

  const onDeleteButtonClick = () => {
    onDelete(licenceData);
  };

  const player: any = players.find(
    (elem: any) => elem.id == licenceData.playerId
  );

  return (
    <StyledTableRow key={licenceData.id} hover>
      <StyledTableCell>
        {player && `${player.name} | ${player.email ? player.email : ''}`}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {licenceData.licenceKey ?? ''}
      </StyledTableCell>
      <StyledTableCell>
        {licenceData.expirationDate
          ? moment(licenceData.expirationDate).format('YYYY-MM-DD')
          : ''}
      </StyledTableCell>
      <StyledTableCell>{STATUSES[licenceData.status]}</StyledTableCell>
      <StyledTableCell align="right">
        {licenceData.hashes
          ? JSON.parse(licenceData.hashes).map((hash: any) => {
              return (
                <Typography style={{ fontFamily: "'Roboto Mono', monospace" }}>
                  {hash}
                </Typography>
              );
            })
          : ''}
      </StyledTableCell>
      <StyledTableCell align="right">
        <IconButton aria-label="edit" onClick={onEditButtonClick}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={onDeleteButtonClick}>
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default PlayersTableRow;
