import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bolder',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: '#FF0000',
    },
  },
}))(TableRow);

type Props = {
  playerData: any;
  onEdit: any;
  onDelete: any;
};

function PlayersTableRow({ playerData, onEdit, onDelete }: Props) {
  const onEditButtonClick = () => {
    onEdit(playerData);
  };

  const onDeleteButtonClick = () => {
    onDelete(playerData);
  };

  return (
    <StyledTableRow key={playerData.id} hover>
      <StyledTableCell>{playerData.name ?? ''}</StyledTableCell>
      <StyledTableCell>{playerData.email ?? ''}</StyledTableCell>
      <StyledTableCell>{playerData.discordId ?? ''}</StyledTableCell>
      <StyledTableCell>{playerData.discordName ?? ''}</StyledTableCell>
      <StyledTableCell>{playerData.patreonId ?? ''}</StyledTableCell>
      <StyledTableCell>{playerData.patreonName ?? ''}</StyledTableCell>
      <StyledTableCell>{playerData.notes ?? ''}</StyledTableCell>
      <StyledTableCell align="right">
        <IconButton aria-label="edit" onClick={onEditButtonClick}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={onDeleteButtonClick}>
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default PlayersTableRow;
