import React, { useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Paper,
  RootRef,
  TextField,
  Theme,
} from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
import dreamApi, { useDreamApi } from '../../../adapters/dreamApi';
import { PlayerDto } from '../../../typings';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import * as crypto from 'crypto';
import * as shajs from 'sha.js';
import { BinaryLike } from 'crypto';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      position: 'absolute',
    },
    dialogContent: {
      position: 'relative',
    },
    error: {
      marginTop: theme.spacing(4),
      color: '#FF0000',
    },
    droppableZone: {
      border: '10px dashed #ccc',
      height: '150px',
      width: '400px',
      cursor: 'pointer',
      marginBottom: theme.spacing(2),
    },
    droppableZoneWrapper: {
      display: 'table',
      width: '100%',
      height: '100%',
    },
    droppableZoneText: {
      display: 'table-cell',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontSize: '33px',
      overflow: 'hidden',
    },
    droppableFile: {
      position: 'absolute',
      top: '0',
      left: '0',
      opacity: '0',
      height: '100%',
      width: '100%',
    },
  })
);

type Inputs = {
  playerId: number;
  name: string;
  email: string;
  discordId: string;
  discordName: string;
  patreonId: string;
  patreonName: string;
  notes: string;
};

function removeEmptyFields(data: any) {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
}

function AddLicenceForm({ open, submit, close, refresh, data, players }: any) {
  const classes = useStyles();
  // const [loading, setLoading] = useState(false);
  // const error = useRef<any>(undefined);

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ vrcVersion: string }>();

  const [, loading, error, uploadClient] = useDreamApi({
    config: {
      url: `clients`,
      method: 'PATCH',
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    onSuccess: (data) => {
      console.log(data);
      refresh();
      close();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const {
    acceptedFiles: acceptedGameAssemblyFile,
    getRootProps: getGameAssemblyRootProps,
    getInputProps: getGameAssemblyInputProps,
  } = useDropzone();

  const {
    acceptedFiles: acceptedClientFile,
    getRootProps: getClientRootProps,
    getInputProps: getClientInputProps,
  } = useDropzone();

  // const { ref, ...rootProps } = getRootProps();
  const sha2 = useRef('');
  const sha = new shajs.sha1();
  const [calculatingHash, setCalculatingHash] = useState(false);

  const onSubmit = (data: any) => {
    removeEmptyFields(data);

    const bodyFormData = new FormData();
    bodyFormData.append('vrcVersion', data.vrcVersion);
    bodyFormData.append('checksum', sha2.current);
    bodyFormData.append('file', acceptedClientFile[0]);

    uploadClient(bodyFormData);
  };

  const files = acceptedGameAssemblyFile.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  useEffect(() => {
    if (acceptedGameAssemblyFile.length === 0) {
      return;
    }

    const acceptedFile = acceptedGameAssemblyFile[0];

    if (acceptedFile.name !== 'GameAssembly.dll') {
      return;
    }

    setCalculatingHash(true);
    // sha1('sha1')
    acceptedGameAssemblyFile[0].arrayBuffer().then((arr) => {
      sha.update(new Uint8Array(arr));
      sha2.current = sha.digest('hex');
      // b9407e0f4d7cdd6923941bc2142dd4039c7fa47e
      // b9407e0f4d7cdd6923941bc2142dd4039c7fa47e
      setCalculatingHash(false);
    });
  }, [acceptedGameAssemblyFile]);

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">Upload Client</DialogTitle>
      <DialogContent dividers={true} className={classes.dialogContent}>
        <div
          {...getClientRootProps({ refKey: 'innerRef' })}
          className={classes.droppableZone}
        >
          <div className={classes.droppableZoneWrapper}>
            <div className={classes.droppableZoneText}>Drop Client here</div>
          </div>
          <input {...getClientInputProps()} className={classes.droppableFile} />
        </div>
        <div
          {...getGameAssemblyRootProps({ refKey: 'innerRef' })}
          className={classes.droppableZone}
        >
          <div className={classes.droppableZoneWrapper}>
            <div className={classes.droppableZoneText}>
              Drop GameAssembly here
            </div>
          </div>
          <input
            {...getGameAssemblyInputProps()}
            className={classes.droppableFile}
          />
        </div>
        <aside>
          {calculatingHash ? (
            <CircularProgress color="inherit" />
          ) : sha2.current ? (
            <>sha1: {sha2.current}</>
          ) : (
            ''
          )}
        </aside>

        {/* <RootRef rootRef={ref}>
          <Paper {...rootProps}>
            <input {...getInputProps()} />
            <p>Drop GameAssembly here</p>
          </Paper>
          <aside>
            <h4>Files</h4>
            <ul>{files}</ul>
            <h3>
              sha1:{' '}
              {calculatingHash ? (
                <CircularProgress color="inherit" />
              ) : (
                sha2.current
              )}
            </h3>
          </aside>
        </RootRef> */}

        {/* <section className="container">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          <aside>
            <h4>Files</h4>
            <ul>{files}</ul>
          </aside>
        </section> */}

        <form>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              name="vrcVersion"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <TextField
                  autoFocus
                  margin="dense"
                  id="vrcVersion"
                  label="VRC Version"
                  type="text"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  fullWidth
                />
              )}
              control={control}
              defaultValue={''}
            />
          </MuiPickersUtilsProvider>
          {loading && (
            <Backdrop
              className={classes.backdrop}
              open={loading}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {error.current && (
            <DialogContentText className={classes.error}>
              {JSON.stringify(error.current)}
            </DialogContentText>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddLicenceForm;
