import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDreamApi } from '../../../adapters/dreamApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      position: 'absolute',
    },
    dialogContent: {
      position: 'relative',
    },
    error: {
      marginTop: theme.spacing(4),
      color: '#FF0000',
    },
  })
);

type Inputs = {
  name: string;
  email: string;
  discordId: string;
  discordName: string;
  patreonId: string;
  patreonName: string;
  notes: string;
};

function removeUnchangedFields(data: any, original: any) {
  Object.keys(data).forEach((key) => {
    if (data[key] === original[key]) {
      delete data[key];
    }
  });
}

function EditPlayerForm({ open, submit, close, refresh, playerData }: any) {
  const classes = useStyles();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const [, loading, error, updatePlayer] = useDreamApi({
    config: {
      url: `players/${playerData.id}`,
      method: 'PATCH',
    },
    onSuccess: (data) => {
      console.log(data);
      refresh();
      close();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const onSubmit = (data: any) => {
    removeUnchangedFields(data, playerData);
    updatePlayer(data);
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">Modify Player</DialogTitle>
      <DialogContent dividers={true} className={classes.dialogContent}>
        <form>
          <Controller
            name="name"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="name"
                onChange={onChange}
                inputRef={ref}
                value={value}
                fullWidth
              />
            )}
            control={control}
            defaultValue={playerData.name}
          />
          <Controller
            name="email"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                onChange={onChange}
                inputRef={ref}
                fullWidth
              />
            )}
            control={control}
            defaultValue={playerData.email}
          />
          <Controller
            name="discordId"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="discordId"
                label="Discord ID"
                type="name"
                onChange={onChange}
                inputRef={ref}
                fullWidth
              />
            )}
            control={control}
            defaultValue={playerData.discordId}
          />
          <Controller
            name="discordName"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="discordName"
                label="Discord Name"
                type="name"
                onChange={onChange}
                inputRef={ref}
                fullWidth
              />
            )}
            control={control}
            defaultValue={playerData.discordName}
          />
          <Controller
            name="patreonId"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="patreonId"
                label="Patreon ID"
                type="name"
                onChange={onChange}
                inputRef={ref}
                fullWidth
              />
            )}
            control={control}
            defaultValue={playerData.patreonId}
          />
          <Controller
            name="patreonName"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="patreonName"
                label="Patreon Name"
                type="name"
                onChange={onChange}
                inputRef={ref}
                fullWidth
              />
            )}
            control={control}
            defaultValue={playerData.patreonName}
          />
          <Controller
            name="notes"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="notes"
                label="Note"
                type="name"
                onChange={onChange}
                inputRef={ref}
                fullWidth
                multiline
                rows={3}
                rowsMax={4}
              />
            )}
            control={control}
            defaultValue={playerData.notes}
          />
          {loading && (
            <Backdrop
              className={classes.backdrop}
              open={loading}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {error.current && (
            <DialogContentText className={classes.error}>
              {JSON.stringify(error.current)}
            </DialogContentText>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditPlayerForm;
