import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, CircularProgress } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import LicencesTableRow from './LicencesTableRow';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bolder',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    minWidth: 275,
  },
  loading: {
    textAlign: 'center',
    alignItems: 'center',
    height: 100,
  },
});

function LicencesTable({
  licences,
  players,
  onAddButtonClick,
  onEditButtonClick,
  onDeleteButtonClick,
  loading,
}: {
  licences: [];
  players: [];
  onAddButtonClick: () => void;
  onEditButtonClick: (player: any) => void;
  onDeleteButtonClick: (player: any) => void;
  loading: boolean;
}) {
  const classes = useStyles();

  // TODO: Status as text
  // TODO: Player id as player/email?
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Player</StyledTableCell>
            <StyledTableCell>Licence Key</StyledTableCell>
            <StyledTableCell>Expiration Date</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell align="right">Hashes</StyledTableCell>
            <StyledTableCell align="right">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={onAddButtonClick}
              >
                New
              </Button>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={6} className={classes.loading}>
                <CircularProgress color="inherit" />
              </TableCell>
            </TableRow>
          ) : (
            licences.map((row) => (
              <LicencesTableRow
                licenceData={row}
                players={players}
                onEdit={onEditButtonClick}
                onDelete={onDeleteButtonClick}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LicencesTable;
