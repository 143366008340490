import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PlayersTableRow from './PlayersTableRow';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bolder',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    minWidth: 275,
  },
  loading: {
    textAlign: 'center',
    alignItems: 'center',
    height: 100,
  },
});

type Props = {
  players: [];
  onAddButtonClick: () => void;
  onEditButtonClick: (player: any) => void;
  onDeleteButtonClick: (player: any) => void;
  loading: boolean;
};

function PlayersTable({
  players,
  onAddButtonClick,
  onEditButtonClick,
  onDeleteButtonClick,
  loading,
}: Props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>E-Mail</StyledTableCell>
            <StyledTableCell>Discord ID</StyledTableCell>
            <StyledTableCell>Discord Name</StyledTableCell>
            <StyledTableCell>Patreon ID</StyledTableCell>
            <StyledTableCell>Patreon Name</StyledTableCell>
            <StyledTableCell>Notes</StyledTableCell>
            <StyledTableCell align="right">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={onAddButtonClick}
              >
                New
              </Button>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={8} className={classes.loading}>
                <CircularProgress color="inherit" />
              </TableCell>
            </TableRow>
          ) : (
            players.map((row) => (
              <PlayersTableRow
                playerData={row}
                onEdit={onEditButtonClick}
                onDelete={onDeleteButtonClick}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PlayersTable;
