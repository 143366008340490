import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import dreamApi, { useDreamApi } from '../../../adapters/dreamApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      position: 'absolute',
    },
    dialogContent: {
      position: 'relative',
    },
    error: {
      marginTop: theme.spacing(4),
      color: '#FF0000',
    },
  })
);

type Inputs = {
  name: string;
  email: string;
  discordId: string;
  discordName: string;
  patreonId: string;
  patreonName: string;
  notes: string;
};

function DeleteLicenceForm({ open, submit, close, refresh, licenceData }: any) {
  const classes = useStyles();

  const [, loading, error, deleteLicence] = useDreamApi({
    config: {
      url: `licences/${licenceData.id}`,
      method: 'DELETE',
    },
    onSuccess: (data) => {
      console.log(data);
      refresh();
      close();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const onSubmit = () => {
    deleteLicence();
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">Delete Player</DialogTitle>
      <DialogContent dividers={true} className={classes.dialogContent}>
        <DialogContentText>
          Are you sure you want to delete licence: {licenceData.licenceKey}?
        </DialogContentText>
        {loading && (
          <Backdrop
            className={classes.backdrop}
            open={loading}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {error.current && (
          <DialogContentText className={classes.error}>
            {JSON.stringify(error.current)}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteLicenceForm;
