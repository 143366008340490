import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {
  Button,
  CardContent,
  CircularProgress,
  Container,
  Modal,
} from '@material-ui/core';

import dreamApi, { useDreamApi } from '../../adapters/dreamApi';
import PlayersTable from './components/PlayersTable';
import SimpleModal from './components/SimpleModal';

import useApi from 'react-use-api';
import AddPlayerForm from './components/AddPlayerForm';
import EditPlayerForm from './components/EditPlayerForm';
import DeletePlayerForm from './components/DeletePlayerForm';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    minWidth: 275,
  },
});

function PlayersPage() {
  const classes = useStyles();
  const [shouldFetch, setShouldFetch] = useState<boolean>(true);
  const players = useRef<any>([]);
  const [error, setError] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  // const [players, loading, , fetchPlayers] = useDreamApi({
  //   url: '/players',
  //   method: 'GET',
  // });

  const [openAddForm, setOpenAddForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const selectedPlayer = useRef<any>(undefined);

  const fetchPlayers = () => {
    setLoading(true);
    dreamApi.get('players').then(({ data }) => {
      players.current = data;
      console.log(players.current);
      setLoading(false);
    });
  };

  console.log(fetchPlayers);

  useEffect(() => {
    fetchPlayers();
  }, []);

  const onAddButtonClick = () => {
    setOpenAddForm(true);
  };

  const onEditButtonClick = (player: any) => {
    selectedPlayer.current = player;
    setOpenEditForm(true);
  };

  const onDeleteButtonClick = (player: any) => {
    selectedPlayer.current = player;
    setOpenDeleteForm(true);
  };

  const onCloseAddForm = () => {
    setOpenAddForm(false);
  };

  const onCloseEditForm = () => {
    selectedPlayer.current = undefined;
    setOpenEditForm(false);
  };

  const onCloseDeleteForm = () => {
    selectedPlayer.current = undefined;
    setOpenDeleteForm(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <PlayersTable
            players={players.current}
            onAddButtonClick={onAddButtonClick}
            onEditButtonClick={onEditButtonClick}
            onDeleteButtonClick={onDeleteButtonClick}
            loading={loading}
          />
        </CardContent>
      </Card>
      {openAddForm && (
        <AddPlayerForm
          open={openAddForm}
          close={onCloseAddForm}
          refresh={fetchPlayers}
        />
      )}
      {openEditForm && (
        <EditPlayerForm
          open={openEditForm}
          close={onCloseEditForm}
          refresh={fetchPlayers}
          playerData={selectedPlayer.current}
        />
      )}
      {openDeleteForm && (
        <DeletePlayerForm
          open={openDeleteForm}
          close={onCloseDeleteForm}
          refresh={fetchPlayers}
          playerData={selectedPlayer.current}
        />
      )}
    </>
  );
}

export default PlayersPage;
