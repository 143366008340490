import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dreamApi from '../../../adapters/dreamApi';
import { PlayerDto } from '../../../typings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      position: 'absolute',
    },
    dialogContent: {
      position: 'relative',
    },
    error: {
      marginTop: theme.spacing(4),
      color: '#FF0000',
    },
  })
);

type Inputs = {
  name: string;
  email: string;
  discordId: string;
  discordName: string;
  patreonId: string;
  patreonName: string;
  notes: string;
};

function removeEmptyFields(data: any) {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
}

function AddPlayerForm({ open, submit, close, refresh, data }: any) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const error = useRef<any>(undefined);

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PlayerDto>();

  const onSubmit = (data: PlayerDto) => {
    error.current = undefined;
    setLoading(true);
    removeEmptyFields(data);
    console.log(data);
    dreamApi
      .post('players', data)
      .then(({ data }) => {
        console.log(data);
        refresh();
        close();
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        error.current = err;
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">Create Player</DialogTitle>
      <DialogContent dividers={true} className={classes.dialogContent}>
        <form>
          <Controller
            name="name"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="name"
                onChange={onChange}
                inputRef={ref}
                value={value}
                fullWidth
              />
            )}
            control={control}
            defaultValue=""
          />
          <Controller
            name="email"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                onChange={onChange}
                inputRef={ref}
                fullWidth
              />
            )}
            control={control}
            defaultValue=""
          />
          <Controller
            name="discordId"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="discordId"
                label="Discord ID"
                type="name"
                onChange={onChange}
                inputRef={ref}
                fullWidth
              />
            )}
            control={control}
            defaultValue=""
          />
          <Controller
            name="discordName"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="discordName"
                label="Discord Name"
                type="name"
                onChange={onChange}
                inputRef={ref}
                fullWidth
              />
            )}
            control={control}
            defaultValue=""
          />
          <Controller
            name="patreonId"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="patreonId"
                label="Patreon ID"
                type="name"
                onChange={onChange}
                inputRef={ref}
                fullWidth
              />
            )}
            control={control}
            defaultValue=""
          />
          <Controller
            name="patreonName"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="patreonName"
                label="Patreon Name"
                type="name"
                onChange={onChange}
                inputRef={ref}
                fullWidth
              />
            )}
            control={control}
            defaultValue=""
          />
          <Controller
            name="notes"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                margin="dense"
                id="notes"
                label="Note"
                type="name"
                onChange={onChange}
                inputRef={ref}
                fullWidth
                multiline
                rows={3}
                rowsMax={4}
              />
            )}
            control={control}
            defaultValue=""
          />
          {loading && (
            <Backdrop
              className={classes.backdrop}
              open={loading}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {error.current && (
            <DialogContentText className={classes.error}>
              {JSON.stringify(error.current)}
            </DialogContentText>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddPlayerForm;
