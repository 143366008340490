import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './LoginPage.css';
import { Redirect } from 'react-router';
import { ROUTES } from '../../consts/routes';
import { useHistory } from 'react-router-dom';
import { setAuth } from '../../adapters/dreamApi';

function LoginPage(props: any, context: any) {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const token = Buffer.from(`${email}:${password}`).toString('base64');
    localStorage.setItem('token', token);
    setAuth(token);
    // TODO: confirm by api
    history.push(ROUTES.DASHBOARD.path);
  };

  const tokenExists = localStorage.getItem('token');

  // TODO: Custom Form Components, useForm
  return tokenExists ? (
    <Redirect to={ROUTES.DASHBOARD.path} />
  ) : (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button block size="lg" type="submit" disabled={!validateForm()}>
          Login
        </Button>
      </Form>
    </div>
  );
}

export default LoginPage;
