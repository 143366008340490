import axios, { AxiosRequestConfig } from 'axios';
import { MutableRefObject, useRef, useState } from 'react';

export const setAuth = (token: string) => {
  if (token !== '') {
    axiosInstance.defaults.headers.common['Authorization'] = `Basic ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `${process.env.REACT_APP_API_BASE_URL}/api/v3`
    : '/api/v3';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

const token = localStorage.getItem('token') ?? '';
setAuth(token);

export const useDreamApi = ({
  config,
  onSuccess = undefined,
  onError = undefined,
}: {
  config: AxiosRequestConfig;
  onSuccess?: (data: any) => any;
  onError?: (error: any) => any;
}) /*: [any, boolean, any, () => Promise<void>]*/ => {
  const [loading, setLoading] = useState(false);
  const error = useRef<any>(undefined);
  const data = useRef<any>(undefined);

  const executeRequest = async (payload?: any) => {
    const cfg = {
      ...config,
    };

    if (payload) {
      cfg.data = payload;
    }

    data.current = undefined;
    error.current = undefined;
    setLoading(true);

    try {
      const response = await axiosInstance.request(cfg);
      data.current = response.data;
      setLoading(false);

      if (onSuccess) {
        onSuccess(data.current);
      }
    } catch (err) {
      if (err.response) {
        // Request made and server responded
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else if (err.request) {
        // The request was made but no response was received
        console.log(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err.message);
      }

      data.current = undefined;
      error.current = err;
      setLoading(false);

      if (onError) {
        onError(error.current);
      }
    }
  };
  return [data.current, loading, error, executeRequest] as const;
};

export default axiosInstance;
