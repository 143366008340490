export type PlayerDto = {
  name: string;
  email: string;
  discordId: string;
  discordName: string;
  patreonId?: string;
  patreonName?: string;
  notes?: string;
};

export type LicenceDto = {
  id: number;
  licenceKey: string;
  hashes: string;
  expirationDate: Date;
  featureMask: string;
  status: LICENCE_STATUSES;
  playerId: number;
};

export enum LICENCE_STATUSES {
  DISABLED = 1000,
  NEW = 2000,
  ACTIVE = 3000,
}
