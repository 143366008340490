import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { LicenceDto } from '../../../typings';
import { STATUSES } from '../../../consts/constants';
import moment from 'moment';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bolder',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: '#FF0000',
    },
  },
}))(TableRow);

type Props = {
  clientData: any;
  onEdit: any;
  onDelete: any;
};

function ClientsTableRow({ clientData, onEdit, onDelete }: Props) {
  const onEditButtonClick = () => {
    onEdit(clientData);
  };

  const onDeleteButtonClick = () => {
    onDelete(clientData);
  };

  return (
    <StyledTableRow key={clientData.id} hover>
      <StyledTableCell>{clientData.vrcVersion}</StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {clientData.uploadDate
          ? moment(clientData.uploadDate).format('YYYY-MM-DD HH:mm')
          : ''}
      </StyledTableCell>
      <StyledTableCell>{clientData.checksum}</StyledTableCell>
      <StyledTableCell>{clientData.path ?? ''}</StyledTableCell>
      <StyledTableCell align="right">
        <IconButton aria-label="delete" onClick={onDeleteButtonClick}>
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default ClientsTableRow;
