const ROUTES = {
  DASHBOARD: {
    label: 'Dashboard',
    path: '/dashboard/overview',
  },
  PLAYERS: {
    label: 'Players',
    path: '/dashboard/players',
  },
  LICENCES: {
    label: 'Licences',
    path: '/dashboard/licences',
  },
  CLIENTS: {
    label: 'Clients',
    path: '/dashboard/clients',
  },
  LOGIN: {
    label: 'Login',
    path: '/login',
  },
};

export { ROUTES };
