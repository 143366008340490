import {
  createStyles,
  CssBaseline,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import ProtectedRoute from '../../components/ProtectedRoute';
import TopBar from '../../components/TopBar';
import { ROUTES } from '../../consts/routes';
import ClientsPage from '../clients/ClientsPage';
import HomePage from '../home/HomePage';
import LicencesPage from '../licences/LicencesPage';
import PlayersPage from '../players/PlayersPage';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  })
);

function DashBoardPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <ProtectedRoute path={ROUTES.DASHBOARD.path}>
          <TopBar title="Dashboard" />
          <HomePage />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.PLAYERS.path}>
          <TopBar title="Players" />
          <PlayersPage />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.LICENCES.path}>
          <TopBar title="Licences" />
          <LicencesPage />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.CLIENTS.path}>
          <TopBar title="Clients" />
          <ClientsPage />
        </ProtectedRoute>
      </main>
    </div>
  );
}

export default DashBoardPage;
