import React from 'react';
import { Route, Redirect, RouteComponentProps, match } from 'react-router-dom';
import * as H from 'history';

const ProtectedRoute = ({ children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem('token') ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
