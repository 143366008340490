import React, { useRef, useState } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
import dreamApi, { useDreamApi } from '../../../adapters/dreamApi';
import { PlayerDto } from '../../../typings';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      position: 'absolute',
    },
    dialogContent: {
      position: 'relative',
    },
    error: {
      marginTop: theme.spacing(4),
      color: '#FF0000',
    },
  })
);

type Inputs = {
  playerId: number;
  name: string;
  email: string;
  discordId: string;
  discordName: string;
  patreonId: string;
  patreonName: string;
  notes: string;
};

function removeEmptyFields(data: any) {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
}

function AddLicenceForm({ open, submit, close, refresh, data, players }: any) {
  const classes = useStyles();
  // const [loading, setLoading] = useState(false);
  // const error = useRef<any>(undefined);

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    moment(new Date()).endOf('day').add(1, 'months').toDate()
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PlayerDto | { playerId: number; expirationDate: Date }>();

  const [, loading, error, createLicence] = useDreamApi({
    config: {
      url: `licences`,
      method: 'POST',
    },
    onSuccess: (data) => {
      console.log(data);
      refresh();
      close();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const onSubmit = (data: PlayerDto) => {
    removeEmptyFields(data);
    createLicence(data);
    // error.current = undefined;
    // setLoading(true);
    // console.log(data);
    // console.log(JSON.stringify(data));
    // dreamApi
    //   .post('licences', data)
    //   .then(({ data }) => {
    //     console.log(data);
    //     refresh();
    //     close();
    //     setLoading(false);
    //   })
    //   .catch((err: any) => {
    //     console.log(err);
    //     error.current = err;
    //     setLoading(false);
    //   });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">Create Licence</DialogTitle>
      <DialogContent dividers={true} className={classes.dialogContent}>
        <form>
          <Controller
            name="playerId"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <Autocomplete
                id="playerId"
                options={players}
                getOptionLabel={(option: any) =>
                  `${option.name} | ${option.email ?? ''}`
                }
                style={{ width: 300 }}
                onChange={(_, data) => onChange((data && data.id) || null)}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Player"
                    // variant="outlined"
                    // value={params}
                  />
                )}
              />
            )}
            control={control}
            defaultValue=""
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              name="expirationDate"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="expirationDate"
                  label="Expiration Date"
                  value={value}
                  onChange={onChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              )}
              control={control}
              defaultValue={selectedDate}
            />
          </MuiPickersUtilsProvider>
          {loading && (
            <Backdrop
              className={classes.backdrop}
              open={loading}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {error.current && (
            <DialogContentText className={classes.error}>
              {JSON.stringify(error.current)}
            </DialogContentText>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddLicenceForm;
