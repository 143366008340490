import React from 'react';

import {
  createStyles,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PublishIcon from '@material-ui/icons/Publish';
import { NavLink } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    navLink: {
      textTransform: 'none',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    navLinkIcon: {
      color: 'inherit',
    },
    navLinkSelected: {
      textTransform: 'none',
      color: '#232222',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    navLinkList: {
      backgroundColor: 'inherit',
    },
  })
);

function NavBar() {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div
        className={classes.toolbar}
        style={{ lineHeight: 64, textAlign: 'center' }}
      >
        <Typography variant="h5" style={{ padding: 10 }}>
          Dream Client
        </Typography>
      </div>
      <Divider />
      <List>
        <NavLink
          to="/dashboard/overview"
          className={classes.navLink}
          activeClassName={classes.navLinkSelected}
        >
          <ListItem button key={'Dashboard'} className={classes.navLinkList}>
            <ListItemIcon>
              <DashboardIcon className={classes.navLinkIcon} />
            </ListItemIcon>
            <ListItemText primary={'Dashboard'} />
          </ListItem>
        </NavLink>
        <NavLink
          to="/dashboard/players"
          className={classes.navLink}
          activeClassName={classes.navLinkSelected}
        >
          <ListItem button key={'Players'} className={classes.navLinkList}>
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary={'Players'} />
          </ListItem>
        </NavLink>
        <NavLink
          to="/dashboard/licences"
          className={classes.navLink}
          activeClassName={classes.navLinkSelected}
        >
          <ListItem button key={'Licences'} className={classes.navLinkList}>
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText primary={'Licences'} />
          </ListItem>
        </NavLink>
        <NavLink
          to="/dashboard/clients"
          className={classes.navLink}
          activeClassName={classes.navLinkSelected}
        >
          <ListItem button key={'Clients'} className={classes.navLinkList}>
            <ListItemIcon>
              <PublishIcon />
            </ListItemIcon>
            <ListItemText primary={'Clients'} />
          </ListItem>
        </NavLink>
      </List>
      <Divider />
    </Drawer>
  );
}

export default NavBar;
