// import React, { useState, useEffect } from 'react';
// import { Route, Switch, Redirect } from 'react-router-dom';

import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { pageVisits } from '../../data/tables';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    root: {
      minWidth: 275,
    },
  })
);

function HomePage() {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography>TBD</Typography>
    </Paper>
  );
}

export default HomePage;
