import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { ROUTES } from './consts/routes';

import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';

import Sidebar from './components/SideBar';
import LoginPage from './pages/login/LoginPage';
import HomePage from './pages/home/HomePage';
import PlayersPage from './pages/players/PlayersPage';

import { useLocalStorage } from 'react-use-storage';
import { setAuth } from './adapters/dreamApi';
import LicencesPage from './pages/licences/LicencesPage';
import ClientsPage from './pages/clients/ClientsPage';
import NavBar from './components/NavBar';
import { CssBaseline } from '@material-ui/core';
import TopBar from './components/TopBar';
import DashBoardPage from './pages/dashboard/DashboardPage';

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route path={ROUTES.LOGIN.path} component={LoginPage}>
          <LoginPage />
        </Route>

        <ProtectedRoute path={'/dashboard'}>
          <DashBoardPage />
        </ProtectedRoute>

        <Redirect to={ROUTES.LOGIN.path} />
      </Switch>
    </HashRouter>
  );
}

export default App;
